
  import { defineComponent } from "vue";

  import {
      Form as VForm,
      Field as VField,
  } from 'vee-validate';

  import { useLoginLogic } from "../_common/login";

  export default defineComponent({
      components: {
          VForm,
          VField,
      },

      setup() {
          return useLoginLogic();
      }
  })
